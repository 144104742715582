import React, { useState } from "react"
import { Search } from "react-feather"
import { navigate } from "gatsby"

const PlzSearchEntry = props => {
  const [zip, setZip] = useState("")
  const handleSearchImmoLeadZip = event => {
    event.preventDefault()
    navigate(`${props.path || "/de/immobilienbewertung/"}?city=${zip}`, {
      state: { isLeadGenerator: true },
    })
  }

  return (
    <form onSubmit={handleSearchImmoLeadZip} {...props}>
      <div className="input-group input-group-md">
        <span className="input-group-text border-0 pe-2">
          <Search />
        </span>

        <input
          type="text"
          className="form-control border-0 px-1"
          aria-label="PLZ / Ort eingeben"
          placeholder="PLZ / Ort eingeben"
          value={zip}
          onChange={e => setZip(e.target.value)}
        />

        <span className="input-group-text border-0 py-0 ps-1 pe-1">
          <button type="submit" className="btn btn-sm btn-primary">
            <span className="d-sm-none">Loslegen</span>
            <span className="d-none d-sm-inline">Jetzt loslegen</span>
          </button>
        </span>
      </div>
    </form>
  )
}

export default PlzSearchEntry

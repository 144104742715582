import React, { useContext, useEffect } from "react"

import LayoutContext from "../../components/LayoutContext"
import HelmetHead from "../../components/HelmetHead"
import PlzSearchEntry from "../../components/PlzSearchEntry"
import Image from "../../components/Image"
import LeadGeneratorLoadable from "../../components/generator/LeadGeneratorLoadable"
import { useLocation } from "@reach/router"
import { Check } from "react-feather"

const Immobilienmakler = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  // define if lead-generator is used in page!
  const canonicalPathname = `/de/immobilienmakler/`
  const fixedPathname = `/de/immobilienmakler/`

  const location = useLocation()
  const isLeadGenerator =
    (location.state && location.state.isLeadGenerator) ||
    location.pathname + location.hash !== fixedPathname

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        fixed: false,
        bgColor: "white",
        color: "light",
      },
    }))
  }, [])
  return (
    <>
      <HelmetHead
        title="Immobilienmakler finden"
        description="Suchen Sie einen professionellen Makler? Bestens geeignet zu Ihrer Immobilie? Wir empfehlen Ihnen den passenden Makler in Ihrer Stadt."
        keywords="Immobilienmakler"
        links={[
          {
            rel: "canonical",
            href: `${process.env.GATSBY_SITE_URL}${canonicalPathname}`,
          },
        ]}
      />
      <section className="section-border border-primary">
        {isLeadGenerator ? (
          <div className="container-fluid pt-6 pt-md-8">
            <div
              className="card card-bleed shadow-light-lg mx-md-auto"
              style={{ minHeight: "495px", maxWidth: "767px" }}
            >
              <div className="scroll-beacon" id="makler-finden" />
              <LeadGeneratorLoadable
                leadGenerator="makler"
                setPath={fixedPathname}
              />
            </div>
          </div>
        ) : (
          <div className="container d-flex flex-column">
            <div
              className="row align-items-center justify-content-center g-0"
              style={{ minHeight: "80vh" }}
            >
              <div className="col-12 col-sm-10 col-md-8 col-lg-6 py-8 py-md-11">
                <Image
                  src="/assets/logo/Maklerzentrale-Schrift_fw.png"
                  loadFrom="logo"
                  loading="eager"
                  style={{ height: "auto", width: "340px" }}
                  className="img-fluid mb-1 d-block mx-auto"
                  alt="Maklerzentrale"
                />

                <p className="mb-4 text-center" style={{ fontSize: "1.53rem" }}>
                  Erstklassige Maklerempfehlung
                </p>

                <PlzSearchEntry
                  // path="/besser-mit-makler/"
                  path="/de/immobilienmakler/"
                  className="rounded shadow mx-auto"
                  style={{ maxWidth: "500px" }}
                />

                <div className="d-flex justify-content-around py-3 px-sm-3 mt-3">
                  <div className="d-none d-sm-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-2">
                      <Check size={15} />
                    </div>
                    <p className="text-success">Unverbindlich</p>
                  </div>
                  <div className="d-none d-sm-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-2">
                      <Check size={15} />
                    </div>
                    <p className="text-success mb-lg-0">Unabhängig</p>
                  </div>
                  <div className="d-none d-sm-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-2">
                      <Check size={15} />
                    </div>
                    <p className="text-success mb-lg-0">Zuverlässig</p>
                  </div>

                  <div className="d-flex d-sm-none">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-2">
                      <Check size={15} />
                    </div>
                    <p className="text-success">
                      Unverbindlich. Unabhängig. Zuverlässig.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default Immobilienmakler

import React from "react"
import Loadable from "@loadable/component"

const LoadableLeadGenerator = Loadable(() => import("./LeadGenerator"))

const LoadableIntuitiveLeadGenerator = Loadable(() =>
  import("./IntuitiveLeadGenerator")
)

export default props =>
  props.intuitive ? (
    <LoadableIntuitiveLeadGenerator {...props}>
      Laden ...
    </LoadableIntuitiveLeadGenerator>
  ) : (
    <LoadableLeadGenerator {...props}>Laden ...</LoadableLeadGenerator>
  )
